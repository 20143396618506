import React from "react";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";

const Wishlist = () => {
  return (
    <>
      <section className="breadscrumb-section pt-0">
  <div className="container-fluid-lg">
    <div className="row">
      <div className="col-12">
        <div className="breadscrumb-contain">
          <h2>Wishlist</h2>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <link to="/" />
                <i className="fa-solid fa-house" />
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Wishlist
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>


      <section className="wishlist-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-3 g-2">
            <div className="col-xxl-2 col-lg-3 col-md-4 col-6 product-box-contain">
              <div className="product-box-3 h-100">
                <div className="product-header">
                  <div className="product-image">
                    <Link>
                      <img
                        src="../assets/images/cake/product/2.png"
                        className="img-fluid blur-up lazyload"
                        alt
                      />
                    </Link>
                    <div className="product-header-top">
                      <button className="btn wishlist-button close_button">
                        <IoMdClose />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="product-footer">
                  <div className="product-detail">
                    <span className="span-name">Vegetable</span>
                    <Link>
                      <h5 className="name">
                        Fresh Bread and Pastry Flour 200 g
                      </h5>
                    </Link>
                    <h6 className="unit mt-1">250 ml</h6>
                    <h5 className="price">
                      <span className="theme-color">₹08.02</span>
                      <del>₹15.15</del>
                    </h5>
                    <div className="add-to-cart-box bg-white mt-2">
                      <button className="btn btn-add-cart addcart-button">
                        Add
                        <span className="add-icon bg-light-gray">
                          <i className="fa-solid fa-plus" />
                        </span>
                      </button>
                      <div className="cart_qty qty-box">
                        <div className="input-group bg-white">
                          <button
                            type="button"
                            className="qty-left-minus bg-gray"
                            data-type="minus"
                            data-field
                          >
                            <i className="fa fa-minus" aria-hidden="true" />
                          </button>
                          <input
                            className="form-control input-number qty-input"
                            type="text"
                            name="quantity"
                            defaultValue={0}
                          />
                          <button
                            type="button"
                            className="qty-right-plus bg-gray"
                            data-type="plus"
                            data-field
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
