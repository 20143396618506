import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  clientOrder: localStorage.getItem("clientOrder")
    ? JSON.parse(localStorage.getItem("clientOrder"))
    : [],

  currentOrder: localStorage.getItem("currentOrder")
    ? JSON.parse(localStorage.getItem("currentOrder"))
    : "",
  paymentUpdateOrder: localStorage.getItem("paymentUpdateOrder")
    ? JSON.parse(localStorage.getItem("paymentUpdateOrder"))
    : "",
  ordersLoading: true,
  isordersLoading: true,
};

export const newOrder = createAsyncThunk(
  "order/newOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/new`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(" grocer order not create");
    }
  }
);

export const getOrderbyClId = createAsyncThunk(
  "order/getOrderbyClId",
  async (clientid = 0, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/tenOrderbyclient/${clientid}`;

      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pincode Not Found");
    }
  }
);
export const gerenatenewTokrnId = createAsyncThunk(
  "order/gerenatenewTokrnId",
  async (orderId = 0, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/generatenewtoken/${orderId}`;

      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pincode Not Found");
    }
  }
);

export const updateOrder = createAsyncThunk(
  "Order/updateOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/${formData.orderid}`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not Updated");
    }
  }
);

const OrderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    clearOrder(state, action) {
      state.clientOrder = [];
      state.currentOrder = "";
      localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
    setpaymentUpdateOrder(state, action) {
      state.paymentUpdateOrder = action.payload.order;
      localStorage.setItem(" paymentUpdateOrder", JSON.stringify(state.paymentUpdateOrder));
    },
    setpaymentUpdateOrderClear(state, action) {
      state.paymentUpdateOrder = "";
      localStorage.setItem(" paymentUpdateOrder", JSON.stringify(state.paymentUpdateOrder));
    },

    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
  },
  extraReducers: {
    [getOrderbyClId.pending]: (state) => {
      state.isordersLoading = true;
    },
    [getOrderbyClId.fulfilled]: (state, action) => {
      state.clientOrder = action.payload.order;
      localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
      state.isordersLoading = false;
    },
    [getOrderbyClId.rejected]: (state, action) => {
      state.isordersLoading = true;
    },
    [newOrder.pending]: (state) => {
      state.ordersLoading = true;
    },
    [newOrder.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.clientOrder = [action.payload.order, ...state.clientOrder];
        state.currentOrder = action.payload.order;
       
      }
      
      localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));

      state.ordersLoading = false;
    },
    [newOrder.rejected]: (state, action) => {
      state.ordersLoading = true;
    },

    [updateOrder.pending]: (state) => {
      state.ordersLoading = true;
    },

    [updateOrder.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.clientOrder = state.clientOrder.filter(
          (order) => order._id !== action.payload.order._id
        );
        state.clientOrder = [action.payload.order, ...state.clientOrder,];
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
      }
      state.ordersLoading = false;
    },

    [updateOrder.rejected]: (state, action) => {
      state.ordersLoading = true;
    },
  },
});
export const { clearOrder,setpaymentUpdateOrder,setpaymentUpdateOrderClear,setCurrentOrder } = OrderSlice.actions;
export default OrderSlice.reducer;
