import React from "react";

const RefundPolicy = () => {
  return (
    <>
      <section className="container  ">
        <h3 className=" text-center return">Refund Policy</h3>
        <hr></hr>
        <div className="">
          <h2 className=" ">Refund Policy for Bayfikar.in [ A UNIT OF <b>HAPPY HOMES</b>]</h2>
          <p className="mt-lg-5">
            Please be advised that when you opt to cancel or return the
            products, upon verification of the products and the records relating
            to, the refund amount for such products which are eligible for
            return as per our norms, will be processed within a period of Seven
            to Eight (7-8) business working days from the date of verifying and
            confirming the refund request from the company. Your refund will be
            processed only when the conditions mentioned by company are
            fulfilled. The refund amount will be credited into the bank account
            or in the Bayfikar Wallet as chosen by the user. You understand that
            after initiation of refund, it may take additional time for your
            amount to reflect into your account depending on your respective
            bank.
          </p>
        </div>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default RefundPolicy;
