import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>About Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="fa-solid fa-house" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fresh-vegetable-section section-lg-space">
        <div className="container-fluid-lg">
          <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
            <div className="col-xl-6 col-12 p-4">
              <div className="row g-sm-4  g-2">
                {/* <div className="col-6">
                  <div className="fresh-image-2">
                    <div>
                      <img
                        src="../assets/images/inner-page/about-us/1.jpg"
                        className="bg-img blur-up lazyload"
                        alt
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="fresh-image">
                    <div>
                      <img
                        src="../assets/images/inner-page/about-us/2.jpg"
                        className="bg-img blur-up lazyload"
                        alt=""
                        width={700}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-12 p-2">
              <div className="fresh-contain p-center-left">
                <div>
                  <div className="review-title">
                    <h4>About Us</h4>
                    <h2>What is Bayfikar.in</h2>
                  </div>
                  <div className="delivery-list">
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
                      Bayfikar  [ A UNIT OF <b>HAPPY HOMES</b>] is an initiative
                      aim to provide world class service to the people of
                      Hyderabad for their food and grocery needs at your
                      doorstep.
                    </p>

                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
                      BAYFIKAR.IN [ A UNIT OF <b>HAPPY HOMES</b>] IN HYDERABAD,
                      online food and grocery store with over 5,000 products and
                      over 500 brands in our catalogue. you will find everything
                      you're looking for with starting from baby diapers, baby
                      food, baby accessories, mom care, fresh fruits, fresh
                      vegetables, rice & dals, spices & seasoning to packaged
                      products, home & kitchen, diary & beverages, beauty &
                      hygiene, clothing & accessories, party, stationery,
                      personal care products, meat & fish we have it all. choose
                      from a wide range of options from every category,
                      exclusively handpicked to help you find the best quality
                      available at the lowest prices.
                    </p>
                    <p
                      className="text-content"
                      style={{ textAlign: "justify" }}
                    >
                      we are purely an internet based company and do not have
                      any brick and mortar stores. You can pay online using your
                      debit / credit card, Internet banking, Payment wallet
                      (Paytm, google pay, Phone pay etc) while ordering or by
                      cash on delivery or by credit/ debit card, payment wallet
                      at the time of delivery. We guarantee instant deliveries,
                      and the best quality!
                    </p>
                    <p className="text-content">
                      <b>
                        GST REGISTRATION NUMBER OF BAYFIKAR.IN [HAPPY HOMES] is
                        36BAZPK3950P1ZO.
                      </b>
                    </p>
                    <ul className="delivery-box">
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/3/delivery.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">
                              Free Delivery For Order Over ₹500
                            </h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/3/leaf.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Every Fresh Products</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Daily Mega Discounts</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="delivery-box">
                          <div className="delivery-icon">
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </div>
                          <div className="delivery-detail">
                            <h5 className="text">Best Price On The Market</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section Start */}
      {/* <footer className="section-t-space ">
        <div className="container-fluid-lg">
          <div className="service-section">
            <div className="row g-3">
              <div className="col-12">
                <div className="service-contain">
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Every Fresh Products</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Free Delivery For Order Over ₹500</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Daily Mega Discounts</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Best Price On The Market</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      {/* Footer Section End */}

      {/* Map Section Start */}
      <section className="map-section">
        <div className="container-fluid p-0">
          <div className="map-box">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14711.19089313938!2d86.2146947!3d22.8099579!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f5e38f14482fa5%3A0x2323c4576f803811!2sKiranaWorld-%20Online%20Grocery%2C%20Vegetables%20%26%20Meat%20delivery%20in%20Jamshedpur!5e0!3m2!1sen!2sin!4v1693460889907!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
      {/* Map Section End */}
    </>
  );
};

export default AboutUs;
