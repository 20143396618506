import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidTimeFive } from "react-icons/bi";
import { FaShoppingCart, FaWallet } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import moment from "moment/moment";
import {
  addressModelStatus,
  currAdress,
  paymentTypeChange,
  updateAddress,
  walletUpdate,
  walletUseUpdate,
} from "../../redux/athentication/Athentication";
import AddAddress from "../../component/account/dashboard/address/AddAddress";
import {
  netpayableUpdate,
  walletamountUpdate,
} from "../../redux/cart/CartSlice";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import CheckoutAddrs from "../../component/account/dashboard/CheckoutAddrs";

const LeftSideCheckout = () => {
  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
    walletUse,
    paymentType,
    clientid,
  } = useSelector((state) => state.Athentication);

  const { netPayable, usedWalletAmount, CartItems } = useSelector(
    (state) => state.Cart
  );

  const dispatch = useDispatch();

  const [selectaddress, setSelectAddress] = useState("");
  const [expectDeliveryTime, setExpectDeliveryTime] = useState("");
  const [walletstatus, setWalletStatus] = useState(Boolean(0));

  const [cartamountDetails, setCartamountDetails] = useState("");

  const [fname, setFname] = useState("");
  const [mobile, setMobile] = useState("");
  const [number, setNumber] = useState("");
  const [altmobile, setAltMobile] = useState(loginData.Mobile);
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [areaArray, setAreaArray] = useState([]);

  const [mainerrormassage, setMainErrormassage] = useState("");

  const addresPinarray = [
    { pinCode: "831001", id: 1 },
    { pinCode: "831002", id: 2 },
    { pinCode: "831003", id: 3 },
    { pinCode: "831004", id: 4 },
    { pinCode: "831005", id: 5 },
    { pinCode: "831006", id: 6 },
    { pinCode: "831007", id: 7 },
    { pinCode: "831008", id: 8 },
    { pinCode: "831009", id: 9 },
    { pinCode: "831010", id: 10 },
    { pinCode: "831011", id: 11 },
    { pinCode: "831012", id: 12 },
    { pinCode: "831013", id: 13 },
    { pinCode: "831014", id: 14 },
    { pinCode: "831015", id: 15 },
    { pinCode: "831016", id: 16 },
    { pinCode: "831017", id: 17 },
    { pinCode: "831018", id: 18 },
    { pinCode: "831019", id: 19 },
    { pinCode: "832109", id: 20 },
    { pinCode: "832110", id: 21 },
  ];
  const addresCityarray = [
    { pinCode: "831001", cityname: "Sakchi", id: 1 },
    { pinCode: "831001", cityname: "Bistupur", id: 2 },
    { pinCode: "831001", cityname: "Sonari", id: 3 },
    { pinCode: "831001", cityname: "Baradwari", id: 4 },
    { pinCode: "831001", cityname: "Dhatkidih", id: 5 },
    { pinCode: "831001", cityname: "XLRI Jamshedpur", id: 6 },

    { pinCode: "831002", cityname: "Parsudihr", id: 7 },

    { pinCode: "831003", cityname: "Golmuri", id: 8 },
    { pinCode: "831003", cityname: "Cable town", id: 9 },
    { pinCode: "831003", cityname: "Sitaramdera", id: 10 },
    { pinCode: "831003", cityname: "Nildih", id: 11 },
    { pinCode: "831003", cityname: "Golmuri", id: 12 },

    { pinCode: "831004", cityname: "Telco", id: 13 },
    { pinCode: "831004", cityname: "Ghorabandha", id: 14 },
    { pinCode: "831004", cityname: "Kharangajhar", id: 15 },
    { pinCode: "831004", cityname: "Azad market", id: 16 },
    { pinCode: "831004", cityname: "Kalimati, sakchi", id: 17 },
    { pinCode: "831004", cityname: "Birsanagar", id: 18 },
    { pinCode: "831004", cityname: "chhota govindpur", id: 19 },

    { pinCode: "831005", cityname: "kadma", id: 20 },
    { pinCode: "831005", cityname: "Uliyan", id: 21 },

    { pinCode: "831006", cityname: "Jugsalai", id: 22 },

    { pinCode: "831007", cityname: "Burmamines", id: 23 },

    { pinCode: "831009", cityname: "Agrico", id: 24 },
    { pinCode: "831009", cityname: "Bhalubasa", id: 25 },
    { pinCode: "831009", cityname: "Sidhgora", id: 26 },
    { pinCode: "831009", cityname: "Bhuiyadih", id: 27 },

    { pinCode: "831011", cityname: "Adarsh Nagar", id: 28 },
    { pinCode: "831011", cityname: "sonari", id: 29 },

    { pinCode: "831012", cityname: "Mango", id: 30 },
    { pinCode: "831012", cityname: "Dimna", id: 31 },
    { pinCode: "831012", cityname: "sahara city, Mango", id: 32 },

    { pinCode: "831013", cityname: "Adityapur-1", id: 33 },

    { pinCode: "831015", cityname: "Chhota Govindpur", id: 34 },

    { pinCode: "831017", cityname: "Vijaya garden, Baridih", id: 35 },
    { pinCode: "831017", cityname: "Baridih colony", id: 36 },
    { pinCode: "831017", cityname: "Birsanagar", id: 37 },
    { pinCode: "831017", cityname: "Baridih", id: 38 },

    { pinCode: "831018", cityname: "MGM Medical college", id: 39 },

    { pinCode: "831019", cityname: "Birsanagar", id: 40 },

    { pinCode: "832109", cityname: "Adityapur", id: 41 },

    { pinCode: "832110", cityname: "Azadnagar, mango", id: 42 },
  ];
  useEffect(() => {
    const filterarreabypin = addresCityarray.filter(
      (pin) => pin.pinCode === pincodecon
    );

    setAreaArray(filterarreabypin);
  }, [pincodecon]);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = Number(cartTotalAmountN) + Number(logisticsAmountN);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    setExpectDeliveryTime(date);
  }, []);

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
    if (numbers.length === 10) {
      setMobile(numbers);
      setErrormassage("");
    } else {
      setMobile("");
      setErrormassage("please enter currect number");
    }
  };

  const selectPincode = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPinCodecon(text);
    // setSupercatid(value);
    // setSupercat(text);
  };
  const selectcity = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCity(text);
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
    // const { value } = evt.target;
    // const index = evt.nativeEvent.target.selectedIndex;
    // setSelectAddress(value);
  };

  const statuschange = async (evt) => {
    const changedStatus = !walletstatus;
    const walletamount = loginData.WalletBalance;
    setWalletStatus(changedStatus);
    if (changedStatus === true) {
      if (cartamountDetails.netPayable > walletamount) {
        dispatch(netpayableUpdate(cartamountDetails.netPayable - walletamount));
        dispatch(walletamountUpdate(walletamount));
        dispatch(walletUseUpdate(true));
      } else {
        dispatch(netpayableUpdate(0));
        dispatch(walletamountUpdate(cartamountDetails.netPayable));
        dispatch(walletUseUpdate(true));
      }
    } else {
      dispatch(netpayableUpdate(cartamountDetails.netPayable));
      dispatch(walletamountUpdate(0));
      dispatch(walletUseUpdate(false));
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    let addresss = [...address];
    if (mobile !== "" && pincodecon !== "" && city !== "") {
      try {
        const formData = {
          AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
          Number: mobile,
          Mobile: altmobile,
          Pincode: pincodecon,
          State: state,
          City: city,
          HNo: houseNo,
          StreetDet: street,
          AreaDet: areaDept,
          LandMark: landmark,
          Address: addres,
          Type: addressType,
          // AName: area,
          ClientId: clientid,
        };

        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const url = `${Baseurl}/api/v1/client/address`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastaddress = addAddress.data.client;

          const currentAddress = [...lastaddress.Addresses];

          // dispatch(currAdress(currentAddress.Addresses[0]));
          dispatch(updateAddress(currentAddress));
          // dispatch(addressModelStatus(false));
        }
      } catch (error) {}
    } else {
      setMainErrormassage("please enter currect values");
    }
  };

  return (
    <>
      <div className="col-lg-8 p-2">
        <div className="left-sidebar-checkout">
          <div className="checkout-detail-box">
            <ul>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdLocationOn style={{ fontSize: "20px", color: "" }} />
                </div>
                <div className="checkout-box">
                  <div className="checkout-detail">
                    {address.length !== 0 && currentAddress !== "" ? (
                      <>
                        {/* <Swiper
                          grabCursor={true}
                          navigation={false}
                          pagination={true}
                          mousewheel={false}
                          keyboard={true}
                          draggable={true}
                          effect={"fade"}
                          modules={[
                            EffectFade,
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                            Autoplay,
                          ]}
                          loop={true}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                          }}
                          className="homeSlider mt-2"
                        >
                          <div className="row g-4">
                            {address.map((data, index) => (
                              <SwiperSlide key={index}>
                                <div
                                  className="col-xxl-6 col-lg-12 col-md-6 mt-2"
                                  key={index}
                                >
                                  <div className="delivery-address-box">
                                    <div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="jack"
                                          id="flexRadioDefault1"
                                          checked={
                                            currentAddress._id === data._id
                                          }
                                          onChange={(e) => {
                                            selectclintddress(e, data);
                                          }}
                                          value={data._id}
                                          required
                                        />
                                      </div>
                                      <div className="label">
                                        <label>{data.Type}</label>
                                      </div>
                                      <ul className="delivery-address-detail">
                                        <li>
                                          {data.AName === "undefined" ? (
                                            <>
                                              <h4 className="fw-500">
                                                {loginData.Name}
                                              </h4>
                                            </>
                                          ) : (
                                            <>
                                              <h4 className="fw-500">
                                                {data.AName}
                                              </h4>
                                            </>
                                          )}
                                        </li>
                                        <li>
                                          <p className="text-content">
                                            <span className="text-title">
                                              Address :{" "}
                                            </span>
                                            {data.Address}
                                          </p>
                                        </li>
                                        <li>
                                          <h6 className="text-content">
                                            <span className="text-title">
                                              Pin Code :
                                            </span>{" "}
                                            {data.Pincode}
                                          </h6>
                                        </li>
                                        <li>
                                          <h6 className="text-content mb-0">
                                            <span className="text-title">
                                              Phone :
                                            </span>{" "}
                                            {data.Mobile}
                                          </h6>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </div>
                        </Swiper> */}
                        <CheckoutAddrs />
                      </>
                    ) : (
                      <>
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => dispatch(addressModelStatus(true))}
                        >
                          <h4
                            style={{
                              fontSize: "20px",
                              color: "red",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            please add address
                          </h4>
                        </div> */}

                        <div className="modal-body">
                          <h5 className="modal-title">
                            <b>Add a new address</b>
                          </h5>
                          <div className="row g-4">
                            <div className="col-xxl-12 mt-4 m-2">
                              <form>
                                <div className="form-floating theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control opacity-75"
                                    id="pname"
                                    placeholder=" "
                                    value={fname}
                                    onChange={(e) => setFname(e.target.value)}
                                    required
                                    style={{ textTransform: "capitalize" }}
                                  />
                                  <label htmlFor="pname">Full Name</label>
                                </div>
                              </form>
                            </div>

                            <div className="row-xxl-12 d-flex">
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      className="form-control"
                                      type="tel"
                                      placeholder=" "
                                      name="mobile"
                                      id="mobile"
                                      maxLength={10}
                                      minLength={10}
                                      value={number}
                                      onChange={(e) => handleChange(e)}
                                      required
                                    />
                                    <label htmlFor="mobile">
                                      Mobile Number
                                    </label>
                                  </div>
                                </form>
                                <p style={{ color: "red" }}>{errormassage}</p>
                              </div>

                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      className="form-control"
                                      type="tel"
                                      placeholder=" "
                                      name="mobile"
                                      id="mobile"
                                      maxLength={10}
                                      minLength={10}
                                      value={altmobile}
                                      readOnly="readOnly"
                                      onChange={(e) =>
                                        setAltMobile(e.target.value)
                                      }
                                    />
                                    <label htmlFor="mobile">
                                      Alternative Mobile Number
                                    </label>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="row-xxl-12 d-flex">
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="pname"
                                      placeholder=" "
                                      value={houseNo}
                                      onChange={(e) =>
                                        setHouseNo(e.target.value)
                                      }
                                      required
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="pname">House No.</label>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="pname"
                                      placeholder=" "
                                      value={street}
                                      onChange={(e) =>
                                        setStreet(e.target.value)
                                      }
                                      required
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="pname">Street</label>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xxl-12 mt-2">
                              <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                  <textarea
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="address"
                                    style={{
                                      height: 75,
                                      textTransform: "capitalize",
                                    }}
                                    defaultValue={""}
                                    value={addres}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                  />
                                  <label htmlFor="address">Enter Address</label>
                                </div>
                              </form>
                            </div>

                            <div className="row-xxl-12 d-flex">
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="landmark"
                                      placeholder=" "
                                      value={landmark}
                                      onChange={(e) =>
                                        setLandmark(e.target.value)
                                      }
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="landmark">Landmark</label>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="areadept"
                                      placeholder=" "
                                      value={areaDept}
                                      required
                                      onChange={(e) =>
                                        setAreaDept(e.target.value)
                                      }
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="areadept">Area Dept</label>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="row-xxl-12 d-flex">
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="pname"
                                      placeholder=" "
                                      value={area}
                                      required
                                      onChange={(e) => setArea(e.target.value)}
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="pname">Area</label>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control opacity-75"
                                      id="state"
                                      placeholder=" "
                                      value={state}
                                      required
                                      onChange={(e) => setState(e.target.value)}
                                      style={{ textTransform: "capitalize" }}
                                    />
                                    <label htmlFor="state">State</label>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="row-xxl-12 d-flex ">
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <select
                                      name="topic"
                                      id="topic"
                                      className="form-control"
                                      onChange={(e) => {
                                        selectPincode(e);
                                      }}
                                      value={addresPinarray.id}
                                      // name={addresPinarray.pinCode}
                                    >
                                      <option value={0}>
                                        {"Select Your Pincode"}
                                      </option>
                                      {addresPinarray.map((data) => (
                                        <option
                                          key={data.id}
                                          value={data.id}
                                          name={data.pinCode}
                                          required
                                        >
                                          {data.pinCode}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </form>
                              </div>
                              <div className="col-xxl-6 m-2">
                                <form>
                                  <div className="form-floating theme-form-floating">
                                    <select
                                      name="topic"
                                      id="topic"
                                      className="form-control"
                                      onChange={(e) => {
                                        selectcity(e);
                                      }}
                                      value={areaArray.id}
                                      // name={areaArray.cityname}
                                    >
                                      <option value={0}>
                                        {"Select Your City"}
                                      </option>
                                      {areaArray.map((data) => (
                                        <option
                                          key={data.id}
                                          value={data.id}
                                          name={data.cityname}
                                          required
                                        >
                                          {data.cityname}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="designAdrs">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="Home"
                                  checked={addressType === "Home"}
                                  onChange={(e) => {
                                    setAddressType("Home");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  Home
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  value="Office"
                                  checked={addressType === "Office"}
                                  onChange={(e) => {
                                    setAddressType("Office");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  Office
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="Others"
                                  checked={addressType === "Others"}
                                  onChange={(e) => {
                                    setAddressType("Others");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault3"
                                >
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "right" }}
                        >
                          <button
                            type="button"
                            className="btn theme-bg-color btn-md text-white"
                            onClick={(e) => onSubmitHandler(e)}
                          >
                            Continue
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BiSolidTimeFive
                    // className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Delivery Time</h4>
                  </div>
                  <div className="checkout-detail">
                    <div className="row g-4">
                      <div className="col-xxl-6">
                        <div className="delivery-option">
                          <div className="delivery-category">
                            <div className="shipment-detail">
                              <div className="form-check custom-form-check hide-check-box">
                                <label
                                  className="form-check-label"
                                  htmlFor="standard"
                                  style={{
                                    fontSize: "20px",
                                    color: "#0e947a",
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {moment(expectDeliveryTime).format(
                                    "DD MMMM YYYY"
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                {/* <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaWallet
                     className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div> */}
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>
                      Wallet Amount :{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "#0e947a",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {loginData.WalletBalance - usedWalletAmount}
                      </span>{" "}
                    </h4>

                    <div className="checkbox-wrapper-6">
                      <div>
                        <input
                          className="tgl tgl-light"
                          id="cb1-6"
                          type="checkbox"
                          value="walletstatus"
                          checked={walletUse === true}
                          onChange={(e) => {
                            statuschange(e);
                          }}
                        />
                        <label className="tgl-btn" htmlFor="cb1-6"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaShoppingCart
                    //  className="lord-icon BiSolidTimeFive text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Payment Option</h4>
                  </div>
                  <div className="checkout-detail">
                    <div
                      className="accordion accordion-flush custom-accordion"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingFour"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="cash"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="cash"
                                  checked={paymentType === "CashOnDelivery"}
                                  onChange={(e) => {
                                    dispatch(
                                      paymentTypeChange("CashOnDelivery")
                                    );
                                  }}
                                />
                                Cash On Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                  checked={paymentType === "paytmPayment"}
                                  onChange={(e) => {
                                    dispatch(paymentTypeChange("paytmPayment"));
                                  }}
                                />
                                Pay Now
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                />
                                Credit or Debit Card
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="row g-2">
                              <div className="col-12">
                                <div className="payment-method">
                                  <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="credit2"
                                      placeholder="Enter Credit & Debit Card Number"
                                    />
                                    <label htmlFor="credit2">
                                      Enter Credit &amp; Debit Card Number
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="expiry"
                                    placeholder="Enter Expiry Date"
                                  />
                                  <label htmlFor="expiry">Expiry Date</label>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="cvv"
                                    placeholder="Enter CVV Number"
                                  />
                                  <label htmlFor="cvv">CVV Number</label>
                                </div>
                              </div>
                              <div className="col-xxl-4">
                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter Password"
                                  />
                                  <label htmlFor="password">Password</label>
                                </div>
                              </div>
                              <div className="button-group mt-0">
                                <ul>
                                  <li>
                                    <button className="btn btn-light shopping-button">
                                      Cancel
                                    </button>
                                  </li>
                                  <li>
                                    <button className="btn btn-animation">
                                      Use This Card
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingTwo">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="banking"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="banking"
                                />
                                Net Banking
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="text-uppercase mb-4">
                              Select Your Bank
                            </h5>
                            <div className="row g-2">
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank1"
                                  >
                                    Industrial &amp; Commercial Bank
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank2"
                                  >
                                    Agricultural Bank
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank3"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank3"
                                  >
                                    Bank of America
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank4"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank4"
                                  >
                                    Construction Bank Corp.
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank5"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank5"
                                  >
                                    HSBC Holdings
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="bank6"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="bank6"
                                  >
                                    JPMorgan Chase &amp; Co.
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="select-option">
                                  <div className="form-floating theme-form-floating">
                                    <select
                                      className="form-select theme-form-select"
                                      aria-label="Default select example"
                                    >
                                      <option value="hsbc">
                                        HSBC Holdings
                                      </option>
                                      <option value="loyds">
                                        Lloyds Banking Group
                                      </option>
                                      <option value="natwest">
                                        Nat West Group
                                      </option>
                                      <option value="Barclays">Barclays</option>
                                      <option value="other">Others Bank</option>
                                    </select>
                                    <label>Select Other Bank</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="wallet"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="wallet"
                                />
                                My Wallet
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <h5 className="text-uppercase mb-4">
                              Select Your Wallet
                            </h5>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="amazon"
                                  >
                                    <input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="amazon"
                                    />
                                    Amazon Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="gpay"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="gpay"
                                  >
                                    Google Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="airtel"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="airtel"
                                  >
                                    Airtel Money
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="paytm"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="paytm"
                                  >
                                    Paytm Pay
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="jio"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="jio"
                                  >
                                    JIO Money
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="custom-form-check form-check">
                                  <input
                                    className="form-check-input mt-0"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="free"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="free"
                                  >
                                    Freecharge
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          size="md"
          isOpen={addressmodel}
          toggle={() => dispatch(addressModelStatus(true))}
        >
          <ModalHeader>
            <AddAddress />
          </ModalHeader>
        </Modal>
      </div>
    </>
  );
};

export default LeftSideCheckout;
