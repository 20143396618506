import React from "react";

const ReturnRefund = () => {
  return (
    <>
      <section className="container return">
        <h3 className="">Return</h3>
        <hr></hr>
        <ul>
          <li className="" style={{ textAlign: "justify" }}>
            <b>1. </b> We have a "no questions asked return at delivery and
            further on for acceptance of returns, cancellation and refunds shall
            be subject to reasonable conditions and will be communicated to the
            User, from time to time, through social platform [I.e Whatsapp] or
            any other mode of communication as specified by the company.
          </li>
        </ul>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default ReturnRefund;
