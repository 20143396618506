import React from "react";

const Privacy = () => {
  return (
    <>
      <section className="container  ">
        <h3 className=" text-center return">Privacy Policy</h3>
        <hr></hr>
        <div className="">
          <h2 className=" ">Privacy Policy for Bayfikar.in  [ A UNIT OF <b>HAPPY HOMES</b>]</h2>
          <p className="mt-lg-5">
            Delivery of an incorrect product or anything different from what
            you’ve ordered.
          </p>
          <p>
            Products that were significantly damaged or deteriorated quality AT
            THE TIME OF delivery. You undertake to provide us with all necessary
            proofs, including pictures and videos of defective products.
          </p>
          <p>
            At the time of delivery, customers must check the product, In Case
            you need to initiate an exchange or return on the platform with the
            necessary documentation. Furthermore, you can contact our customer
            service to return or exchange the products. Hence, IT IS INFORMED
            THAT NO EXCHANGE OR RETURNS WILL BE ACCEPTED IF THE PRODUCT
            PACKAGING IS TAMPERED OR PRODUCT IS USED/CONSUMED/DAMAGED.
          </p>
          <p>
            You may request exchange or return the products, purchased from us
            provided the products are sealed/untapped and in original condition
            and on the same day of delivery. Please note, Requests for
            replacements or refund will not be answered from the day shortly
            after the product was delivered to you. It takes some time.
          </p>
        </div>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default Privacy;
