import React from "react";

const CancellationPolicy = () => {
  return (
    <>
      <section className="container  ">
        <h3 className=" text-center return">Cancellation Policy</h3>
        <hr></hr>
        <div className="">
          <h2 className=" ">Cancellation Policy for Bayfikar.in [ A UNIT OF <b>HAPPY HOMES</b>]</h2>
          <p className="mt-lg-5">
            You may cancel an order with no charges at any time before the
            platform accepts your order. You cannot cancel the order after
            acceptance of order by the platform. The Company may cancel an order
            [IN PART OR FULLY] for the reasons including shortage or
            unavailability of certain products. In case the platform cancels any
            order, you will not be charged for any cancellation, and the Company
            will refund you for any payment already made.
          </p>
        </div>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default CancellationPolicy;
