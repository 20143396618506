import React from "react";

const Faq = () => {
  return (
    <>
      <section className="faq-box-contain section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <div className="faq-contain">
                <h2>Frequently Asked Questions</h2>
                <p>
                  We are answering most frequent questions. No worries if you
                  not find exact one. You can find out more by searching or
                  continuing clicking button below or directly{" "}
                  <a
                    href="mailto: care@bayfikar.in"
                    className="theme-color text-decoration-underline"
                  >
                    contact our support.
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="faq-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        How do I register?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          You can register by clicking on the "Sign Up" link at
                          the top of the homepage. Please provide the
                          information in the form that appears. You can review
                          the terms and conditions, provide your payment mode
                          details and submit the registration information.
                        </p>
                        <p>
                          Registration on bayfikar.in is absolutely free.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Do I have to necessarily register to shop on
                        Bayfikar?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          You can surf and add products to the cart without
                          registration but only registered shoppers will be able
                          to checkout and place orders. Registered members have
                          to be logged in at the time of checking out the cart,
                          they will be prompted to do so if they are not logged
                          in.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Can I have multiple registrations?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Each email address and contact phone number can only
                          be associated with one Bayfikar account.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        Can I add more than one delivery address in an account?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Yes, you can add multiple delivery addresses in your
                          Bayfikar account. However, remember that all items
                          placed in a single order can only be delivered to one
                          address. If you want different products delivered to
                          different address you need to place them as separate
                          orders.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Can I have multiple accounts with same mobile number and
                        email id?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Each email address and phone number can be associated
                          with one Bayfikar account only.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Can I have multiple accounts for members in my family
                        with different mobile number and email address but same
                        or common delivery address?{" "}
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Yes, we do understand the importance of time and the
                          toil involved in shopping groceries. Up to three
                          members in a family can have the same address provided
                          the email address and phone number associated with the
                          accounts are unique.
                        </p>
                        <p>you can place orders for multiple cities.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        What is My Account?{" "}
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          My Account is the section you reach after you log in
                          at Bayfikar. My Account allows you to track your
                          active orders, credit note details as well as see your
                          order history and update your contact details.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        How do I reset my password?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          You need to enter your email address on the Login page
                          and click on forgot password. An email with a reset
                          password will be sent to your email address. With
                          this, you can change your password. In case of any
                          further issues please contact our customer support
                          team.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        What are credit notes & where can I see my credit notes?
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Credit notes reflect the amount of money which you
                          have pending in your Bayfikar account to use
                          against future purchases. This is calculated by
                          deducting your total order value minus undelivered
                          value. You can see this in “My Account” under credit
                          note details.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
