import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  CartItems: localStorage.getItem("CartItems")
    ? JSON.parse(localStorage.getItem("CartItems"))
    : [],
  ProductTotal: localStorage.getItem("ProductTotal")
    ? JSON.parse(localStorage.getItem("ProductTotal"))
    : [],
  isProductAvailable: localStorage.getItem("ProductTotal") ? true : false,
  groceryProduct: [],
  groceryHotProduct: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  netPayable: 0,
  updateNetPayable: 0,
  cartTotalMrp: 0,
  cartTotalPrice: 0,
  cartPackQuantity: 0,
  cartHandling: 0,
  cartCouponDiscount: 0,
  CouponDiscountAmount: 0,
  cartCouponCode: "",
  cartCoupon: "",
  logisticsAmount: 40, //DeliveryCharge
  usedWalletAmount: 0,
  productUpdated: false,
  Cartloading: true,

  SmallCartFee: 0,
  HandllingFee: 0,
  RainFee: 0,
  RainStatus: false,
  paymentId: "",

  //search
  subcatSearchProducts: [],
  searchSlugUrl: "",
  catSearchProducts: [],
  catsearchSlugUrl: "",
  SearchProducts: [],
  prosearchSlugUrl: "",
  vegCatProduct: [],
  freshfruitCatProduct: [],
  cleaningessentialCatProduct: [],
  pharamCatProduct: [],
  dryfruitCatProduct: [],
  chikenCatProduct: [],
  muttonCatProduct: [],
  fishCatProduct: [],
  singleWebsiteSetting: "",

  isProductLoading: true,
};

export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const resp = await axios.get(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    setpaymentId(state, action) {
      state.paymentId = action.payload;
    },
    InitialmovetoCart(state, action) {
      state.CartItems = action.payload;
    },
    setsingleWebsiteSetting(state, action) {
      state.singleWebsiteSetting = action.payload;
    },

    addtoCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item.packsizeId === action.payload.packsizeId
      );
      if (itemIndex >= 0) {
        state.cartTotalQuantity++;
        let quant = state.CartItems[itemIndex].Qty + 1;

        state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty + 1;
        state.CartItems[itemIndex].TotalMrp = Number(
          state.CartItems[itemIndex].Mrp * Number(quant)
        );
        state.CartItems[itemIndex].TotalPrice = Number(
          state.CartItems[itemIndex].SellingPrice * Number(quant)
        );
        state.CartItems[itemIndex].TotalAmount = Number(
          state.CartItems[itemIndex].SellingPrice * Number(quant)
        );

        state.cartTotalAmount =
          Number(state.cartTotalAmount) + Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 100) {
          state.SmallCartFee = 0;
        } else {
          state.SmallCartFee = 30;
        }
        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }
        if (state.RainStatus === true) {
          state.RainFee = 20;
        } else {
          state.RainFee = 0;
        }

        state.netPayable =
          Number(state.cartTotalAmount) +
          Number(state.logisticsAmount) +
          Number(state.SmallCartFee) +
          Number(state.RainFee) +
          Number(state.HandllingFee);
      } else {
        const tempProducts = action.payload;
        state.CartItems.push(tempProducts);

        state.cartTotalQuantity++;
        state.cartPackQuantity++;

        state.cartTotalAmount =
          Number(state.cartTotalAmount) + Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 100) {
          state.SmallCartFee = 0;
        } else {
          state.SmallCartFee = 30;
        }
        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }
        if (state.RainStatus === true) {
          state.RainFee = 20;
        } else {
          state.RainFee = 0;
        }

        state.netPayable =
          Number(state.cartTotalAmount) +
          Number(state.logisticsAmount) +
          Number(state.SmallCartFee) +
          Number(state.RainFee) +
          Number(state.HandllingFee);
      }
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    decrementCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item.packsizeId === action.payload.packsizeId
      );

      if (itemIndex >= 0) {
        if (state.CartItems[itemIndex].Qty > 1) {
          let quant = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].TotalMrp = Number(
            state.CartItems[itemIndex].Mrp * Number(quant)
          );

          state.CartItems[itemIndex].TotalPrice = Number(
            state.CartItems[itemIndex].SellingPrice * Number(quant)
          );
          state.CartItems[itemIndex].TotalAmount = Number(
            state.CartItems[itemIndex].SellingPrice * Number(quant)
          );
        } else if (state.CartItems[itemIndex].Qty === 1) {
          const nextCartItems = state.CartItems.filter(
            (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
          );
          state.cartPackQuantity--;
          state.CartItems = nextCartItems;
        }

        state.cartTotalAmount =
          Number(state.cartTotalAmount) - Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 100) {
          state.SmallCartFee = 0;
        } else {
          state.SmallCartFee = 30;
        }
        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }
        if (state.RainStatus === true) {
          state.RainFee = 20;
        } else {
          state.RainFee = 0;
        }

        state.netPayable =
          Number(state.cartTotalAmount) +
          Number(state.logisticsAmount) +
          Number(state.SmallCartFee) +
          Number(state.RainFee) +
          Number(state.HandllingFee);
        state.Cartloading = !state.Cartloading;
        localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
      }
    },
    removefromCart(state, action) {
      const nextCartItems = state.CartItems.filter(
        (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
      );
      state.cartPackQuantity--;
      state.CartItems = nextCartItems;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    getCartTotal(state, action) {
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartTotalPrice = 0;
      const totalitem = state.CartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.cartTotalAmount =
          Number(state.cartTotalAmount) +
          Number(state.CartItems[i].TotalAmount);
        state.cartTotalMrp =
          Number(state.cartTotalMrp) + Number(state.CartItems[i].TotalMrp);
        state.cartTotalPrice =
          Number(state.cartTotalPrice) + Number(state.CartItems[i].TotalPrice);
      }
      if (state.cartTotalAmount >= 100) {
        state.SmallCartFee = 0;
      } else {
        state.SmallCartFee = 30;
      }
      if (state.cartTotalAmount >= 500) {
        state.logisticsAmount = 0;
      } else {
        state.logisticsAmount = 40;
      }
      if (state.RainStatus === true) {
        state.RainFee = 20;
      } else {
        state.RainFee = 0;
      }
      state.netPayable =
        Number(state.cartTotalAmount) +
        Number(state.logisticsAmount) +
        Number(state.SmallCartFee) +
        Number(state.RainFee) +
        Number(state.HandllingFee);
    },

    clearCart(state, action) {
      state.CartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;

      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },

    couponUpdate(state, action) {
      state.cartCoupon = action.payload;
    },
    setCouponDiscountAmount(state, action) {
      if (state.cartTotalAmount >= state.cartCoupon.MinAmount) {
        state.CouponDiscountAmount = action.payload;
      } else {
        state.CouponDiscountAmount = 0;
      }
    },
    setcartCouponDiscount(state, action) {
      state.cartCouponDiscount = action.payload;
    },


    //search
    setSubcatSearchProducts(state, action) {
      state.subcatSearchProducts = action.payload;
      state.catSearchProducts = [];
      state.SearchProducts = [];
    },
    setSearchSlugUrl(state, action) {
      state.searchSlugUrl = action.payload;
      state.catsearchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setCatSearchProducts(state, action) {
      state.catSearchProducts = action.payload;
      state.subcatSearchProducts = [];
      state.SearchProducts = [];
    },
    setCatsearchSlugUrl(state, action) {
      state.catsearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setSearchProducts(state, action) {
      state.SearchProducts = action.payload;
    },
    setProsearchSlugUrl(state, action) {
      state.prosearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.catsearchSlugUrl = "";
    },

    setsearchEmplty(state, action) {
      state.searchSlugUrl = "";
      state.subcatSearchProducts = [];
      state.catsearchSlugUrl = "";
      state.catSearchProducts = [];
      state.prosearchSlugUrl = "";
      state.SearchProducts = [];
    },
    netpayableUpdate(state, action) {
      state.updateNetPayable = action.payload;
    },
    walletamountUpdate(state, action) {
      state.usedWalletAmount = action.payload;
    },
    setgetCart(state, action) {
      state.CartItems = action.payload;
    },
  },
  extraReducers: {
    [getProduct.pending]: (state) => {
      state.isProductLoading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.ProductTotal = action.payload;
      state.ProductTotal = state.ProductTotal.filter(
        (product) => product.PackSizes.length !== 0
      );
      state.groceryProduct = state.ProductTotal.filter(
        (product) => product.superCategoryId === "63d8da4ad405398b770b50aa"
      );
      state.groceryHotProduct = state.ProductTotal.filter(
        (product) => product.HotProducts === true
      );

      localStorage.setItem("ProductTotal", JSON.stringify(state.ProductTotal));

      localStorage.setItem(
        "groceryProduct",
        JSON.stringify(state.groceryProduct)
      );
      state.isProductLoading = false;
      state.isProductAvailable = true;
    },
    [getProduct.rejected]: (state, action) => {
      state.isProductLoading = true;
    },
  },
});

export const {
  setpaymentId,
  InitialmovetoCart,
  addtoCart,
  decrementCart,
  getCartTotal,
  clearCart,
  allProductUpdate,
  setSubcatSearchProducts,
  setSearchSlugUrl,
  setCatSearchProducts,
  setCatsearchSlugUrl,
  setSearchProducts,
  setProsearchSlugUrl,
  setsearchEmplty,
  couponUpdate,
  setCouponDiscountAmount,
  setcartCouponDiscount,
  setsingleWebsiteSetting,
  removefromCart,
  netpayableUpdate,
  walletamountUpdate,
  primaeryChange,
  setgetCart,
} = CartSlice.actions;
export default CartSlice.reducer;
