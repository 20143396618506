import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./component/layout/MainLayout";
import Home from "./pages/Home";
import { getCategory } from "./redux/category/CategorySlice";
import { getSubCategory } from "./redux/subCategory/SubCategorySlice";
import { getProduct, setsingleWebsiteSetting } from "./redux/cart/CartSlice";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import Cart from "./pages/Cart";
import CheckOut from "./pages/CheckOut";
import OrderSuccess from "./pages/OrderSuccess";
import Error404 from "./pages/Error404";
import ProductDetails from "./pages/ProductDetails";
import OtpScreen from "./pages/Login/OtpScreen";
import Login from "./pages/Login";
import Registation from "./pages/Registation";
import Account from "./component/account/Account";
import {
  getSubCatSmallBanners,
  getSuperCatBanners,
} from "./redux/AllBanners/AllBannersSlice";
import { getSubCatBanners } from "./redux/subcatSlider/SubCatSlider";
import { getTodayFeaturerBanners } from "./redux/AllBanners/TodayFeatureSlice";
import DashBoard from "./component/account/DashBoard";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import ReturnRefund from "./pages/ReturnRefund";
import TermsConditions from "./pages/TermsConditions";
import AboutUs from "./pages/AboutUs";
import OrderTracking from "./pages/OrderTracking";
import Wishlist from "./pages/Wishlist";
import HomeCategorySearch from "./pages/HomeCategorySearch";
import { getOrderbyClId } from "./redux/order/OrderSlice";
import HomeSliderProducts from "./pages/HomeSliderProducts";
import HomeProductSerch from "./pages/HomeProductSerch";
import HTBMainPage from "./pages/HTBMainPage";
import { Baseurl } from "./config/BaseUrl";
import axios from "axios";
import ContactUs from "./pages/ContactUs";
import {
  authActions,
  getClientByMob,
  noaddressUpdate,
  showGroceryStatus,
} from "./redux/athentication/Athentication";
import Subcategoey from "./pages/Home/Subcategoey";
import FailedPaymentStatus from "./pages/paymentStatus/FailedPaymentStatus";
import SuccessPaymentStatus from "./pages/paymentStatus/SuccessPaymentStatus";
import PendingPaaymentStatus from "./pages/paymentStatus/PendingPaaymentStatus";
import SmsSend from "./pages/SmsSend";
import HotProductDetails from "./pages/Home/HotProductDetails";
import CartDemo from "./pages/cart/CartDemo";
import LoginScreen from "./pages/Login/LoginScreen";
import CancellationPolicy from "./pages/CancellationPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";

function App() {
  const dispatch = useDispatch();
  const { isAuth, clientid, loginData, address } = useSelector(
    (state) => state.Athentication
  );
  const {
    isCategoryAvailable,
    isCategoryLoading,
    categoryTotal,
    isSubCategoryAvailable,
    isSubCategoryLoading,
    subCategoryTotal,
  } = useSelector((state) => state.Category);
  const {
    isBannerAvailable,
    isBannerLoading,
    VegBannerTotal,
    isSubCatSmallBannersAvailable,
    isSubCatSmallBannersLoading,
    VegSubcatBannerTotal,
  } = useSelector((state) => state.HomeBanner);
  const { isSuCatSliderAvailable, isSliderLoading, suCatSliderTotal } =
    useSelector((state) => state.SubCategorySlider);
  const {
    isTodayFeaturerAvailable,
    isTodayFeaturerLoading,
    TodayFeatureTotal,
  } = useSelector((state) => state.TodayFeature);
  const { isProductAvailable, isProductLoading, ProductTotal } = useSelector(
    (state) => state.Cart
  );

  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  useEffect(() => {
    try {
      const fetchdata = async () => {
        const url = `${Baseurl}/api/v1/websitesetting/singleby/6645bb4cc1e41fea0b18ed2b`;
        const fetchSetting = await axios(url);
        if (fetchSetting.data.success === true) {
          let singleSetting = fetchSetting.data.websiteSettings;
          dispatch(setsingleWebsiteSetting(singleSetting))
        }
      };
      fetchdata();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (!isBannerAvailable || VegBannerTotal.length === 0) {
      dispatch(getSuperCatBanners());
    }
    if (!isCategoryAvailable || categoryTotal.length === 0) {
      dispatch(getCategory());
    }
    if (!isSubCategoryAvailable || subCategoryTotal.length === 0) {
      dispatch(getSubCategory());
    }

    if (!isSuCatSliderAvailable || suCatSliderTotal.length === 0) {
      dispatch(getSubCatBanners());
    }
    if (!isSubCatSmallBannersAvailable || VegSubcatBannerTotal.length === 0) {
      dispatch(getSubCatSmallBanners());
    }
    if (!isProductAvailable || ProductTotal.length === 0) {
      dispatch(getProduct());
    }
    if (!isTodayFeaturerAvailable || TodayFeatureTotal.length === 0) {
      dispatch(getTodayFeaturerBanners());
    }

    if (address.length === 0) {
      dispatch(noaddressUpdate());
    }

    if (isAuth) {
      dispatch(getOrderbyClId(clientid));

      const fetchdta = async () => {
        const formData = {
          Mobile: loginData.Mobile,
        };
        const ClientDetails = await dispatch(getClientByMob(formData));
        if (ClientDetails.payload.success === false) {
        } else {
          dispatch(
            authActions.signin({
              ...ClientDetails.payload.client,
              isAuth: true,
            })
          );
        }
      };
      fetchdta();
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const url = `${Baseurl}/api/v1/massage/getmassage`;

      const respo = await axios.get(url);
      if (respo.data.success === true) {
        const respoData = respo.data.message;
        dispatch(showGroceryStatus(respoData));
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isBannerLoading) {
        dispatch(getSuperCatBanners());
      }
      if (isCategoryLoading) {
        dispatch(getCategory());
      }
      if (isSubCategoryLoading) {
        dispatch(getSubCategory());
      }

      if (isSliderLoading) {
        dispatch(getSubCatBanners());
      }
      if (isSubCatSmallBannersLoading) {
        dispatch(getSubCatSmallBanners());
      }
      if (isTodayFeaturerLoading) {
        dispatch(getTodayFeaturerBanners());
      }
      if (isProductLoading) {
        dispatch(getProduct());
      }
    }, 15000);
  }, []);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path="/sendsms/:mob/:otpWithHash" element={<SmsSend />} />
          <Route path="/" exact element={<MainLayout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/:caturl" element={<Category />} />
            <Route path="/:caturl/:subcat" element={<Subcategoey />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/CartDemo" element={<CartDemo />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/order-success" element={<OrderSuccess />} />
            <Route path="/payment-failed" element={<FailedPaymentStatus />} />
            <Route path="/payment-success" element={<SuccessPaymentStatus />} />
            <Route
              path="/payment-pending"
              element={<PendingPaaymentStatus />}
            />
            <Route
              path="/product-info/:producturl"
              element={<ProductDetails />}
            />
            {/* <Route path="/login" element={<Login />} /> */}

            <Route path="/registation" element={<Registation />} />
            <Route path="/account" element={<DashBoard />} />
            <Route path="/testaccount" element={<Account />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/returnefund" element={<ReturnRefund />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/cancellation" element={<CancellationPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/ordertracking/:orderId" element={<OrderTracking />} />
            <Route path="/HotProductDetails" element={<HotProductDetails />} />

            {/*============================ searchpages start =================== */}

            <Route
              path="/category-search/:catsearch"
              element={<HomeCategorySearch />}
            />

            <Route
              path="/product-search/:prodsearch"
              element={<HomeProductSerch />}
            />

            {/*============================ searchpages end =================== */}

            {/*============================ slider start =================== */}
            <Route path="/home-slider/:htsurl" element={<HTBMainPage />} />

            <Route path="/home-slider" element={<HomeSliderProducts />} />
            {/*============================ slider end =================== */}
          </Route>
          <Route path="*" element={<Error404 />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
